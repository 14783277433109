import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { NavController } from '@ionic/angular';
// import { LoadingController } from '@ionic/angular';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastController } from '@ionic/angular';
import { sha256, sha224 } from 'js-sha256';
import { sha512 } from 'js-sha512';
import { PhonePe } from 'phonepesdk-web';

var bolt;

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  public internet:String;
  public URL =  'https://www.hoponindia.com/multitenant/api/web/';
  // public tenant_id:any = '60';
  public user:any;
  public cityId:any;
  public nearByClosed = false;
  public downloadCount = 0;
  public isAuth:any; 
  public loading:any;
  public downloadError = false;

  setCityId(id){
    return new Promise((resolve, reject) => {
      this.cityId = id;
      return resolve(null);
    })
  }

  setNearby(value){
    this.nearByClosed = value;
  }

  getNearBy(){
    return this.nearByClosed;
  }

  getCityId(){
    return this.cityId;
  }

  setToken(key,value){
    return new Promise((resolve, reject) => {
      if(key == 'pwa_user'){
        if(navigator.userAgent.includes("phonepe-webview")){
          sessionStorage.setItem(key,value);
          resolve(null);
        }
        else{
          localStorage.setItem(key,value);
          resolve(null);  
        }
      }
      else{
        localStorage.setItem(key,value);
        resolve(null);
      }
    });
  }

  getToken(key){
    if(key == 'pwa_user'){
      if(navigator.userAgent.includes("phonepe-webview")){
        return sessionStorage.getItem(key);
      }
      else{
        return localStorage.getItem(key);
      }
    }
    else{
      return localStorage.getItem(key);
    }
  }

  removeToken(key){
    if(key == 'pwa_user'){
      if(navigator.userAgent.includes("phonepe-webview")){
        sessionStorage.removeItem(key);
      }
      else{
        localStorage.removeItem(key);
      }
    }
    else{
      localStorage.removeItem(key);
    }
  }

  resetDownloadCount(){
    this.downloadCount = 0;
  }

  setDownloadCount(){
    this.downloadCount = this.downloadCount+1;
  }

  getDownloadCount(){
    return this.downloadCount;
  }

  setDownloadError(){
    this.downloadError = true;
  }

  resetDownloadError(){
    this.downloadError = false;
  }

  getDownloadError(){
    return this.downloadError;
  }

  setConnection(connection){
    this.internet = connection;
  }

  getConnection(){
    return this.internet;
  }

  logout(){
    this.removeToken('pwa_user');
  }

  showLoader () {
    this.spinner.show();
  }

  hideLoader () {
    this.spinner.hide();
  }

  encodeSHA (key){
    return sha256(key);
  }

  encode512 (key){
    return sha512(key);
  }

  async createToast(message) {
    console.log('called');
    const toast:any = await this.toastController.create({
        message: message,
        duration: 2000
    });
    toast.present();
  }

  phonepeLogin (grant) {
    return new Promise((resolve, reject) => {
      let grantToken ={
        'grantToken': grant
      };
      this.showLoader();
      let encodedRequest = btoa(JSON.stringify(grantToken));
      // Production
      let verify = this.encodeSHA(encodedRequest+"/v3/service/auth/access"+"5717a83e-6ffb-4e01-ad68-132400997a72") + '###3';
      let userDetailVerify = this.encodeSHA("/v3/service/userdetails"+"5717a83e-6ffb-4e01-ad68-132400997a72") + '###3';

      let fd = new FormData();
      fd.append('x_verify', verify);
      fd.append('x_verify_details', userDetailVerify);
      fd.append('request', encodedRequest)
      this.http.post('https://www.hoponindia.com/multitenant/api/web/v7/login/phonepay-access', fd).subscribe((data:any) => {
        this.hideLoader();
        resolve(data);
      }, (err) =>{
        this.hideLoader();
        resolve(err);
      });
    })
  }
  
  constructor(private httpService: HTTP, private http:HttpClient, private navCtrl:NavController, private Injector:Injector, private spinner: NgxSpinnerService, private toastController:ToastController) { 
    
    if(this.getToken('pwa_user')){
      this.isAuth = true;
      
    }
    else{
      this.isAuth = false;
    }
  }
  
  API(method:string, link, data, detailTour?){
    this.user = JSON.parse(this.getToken('pwa_user'));
    let url = '';
    if(detailTour){
      url = this.URL + 'v7' + link;
    }
    else{
      url = this.URL + 'v5' + link;
    }
    
    
    return new Promise( async (resolve, reject) => {
      if(!url.includes('user/citylist') && !url.includes('user/checklogin') && !url.includes('user/hash')){
        this.showLoader();
      }
      PhonePe.PhonePe.build(PhonePe.Constants.Species.web).then(ppsdk => {
        
        let headers:any;
        if(this.getToken('pwa_user')){
          // console.log('auth true')
          headers = new HttpHeaders({'tenant_id':'60', 'user_id': this.user.user_id, token: this.user.token, device:'pwa'});
        }
        else{
          // console.log('auth false')
          headers = new HttpHeaders({'tenant_id':'60'});
        }

        // Tenant id is 60
        if(method == 'get'){
          this.http.get(url, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, (err) => {
            this.hideLoader();
            resolve(err);
          });
        }
        else if(method == 'post'){
          this.http.post(url, data, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, error => {
            this.hideLoader();
            resolve(error);
          });
        }
        else if(method == 'put'){
          this.http.put(url, data, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, error => {
            this.hideLoader();
            resolve(error);
          });
        }
        else if(method == 'delete'){
          this.http.delete(url, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, error => {
            this.hideLoader();
            resolve(error);
          });
        }
      }).catch((e) => {

        let headers:any;
        if(this.getToken('pwa_user')){
          // console.log('auth true')
          headers = new HttpHeaders({'tenant_id':'1', 'user_id': this.user.user_id, token: this.user.token, device:'pwa'});
        }
        else{
          // console.log('auth false')
          headers = new HttpHeaders({'tenant_id':'1'});
        }

        // Tenant id is 1
        if(method == 'get'){
          this.http.get(url, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, (err) => {
            this.hideLoader();
            resolve(err);
          });
        }
        else if(method == 'post'){
          this.http.post(url, data, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, error => {
            this.hideLoader();
            resolve(error);
          });
        }
        else if(method == 'put'){
          this.http.put(url, data, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, error => {
            this.hideLoader();
            resolve(error);
          });
        }
        else if(method == 'delete'){
          this.http.delete(url, {headers}).subscribe((data:any) => {
            this.hideLoader();
            if(data.auth_failed == '1'){
              resolve(data)
            }
            else{
              resolve(data);
            }
          }, error => {
            this.hideLoader();
            resolve(error);
          });
        }
      });
      
    })
  }


}
