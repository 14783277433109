import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ResolverService implements Resolve<any> {

  constructor(private service : ApiService) { }



  resolve(route: ActivatedRouteSnapshot, sroute: RouterStateSnapshot):Observable<any> | Promise<any>{
    // console.log(route)
    let tourId:any;
    if(route.queryParams.tour){
      tourId = route.queryParams.tour;
    }
    else if(route.params.tour_id && route.params.point_id){
      tourId = route.params.tour_id;
    }
    else{
      tourId = this.service.getToken('currentTour');
    }
    let fd = new FormData();
    fd.append('tour_id',tourId);
    return this.service.API('post', '/user/tourlist', fd, true);
  }
}
