import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../../common.component';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-detail-modal',
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss'],
})
export class DetailModalComponent extends BaseComponent implements OnInit {

  public headerData:any;
  public data:any = {};

  constructor(private Injector:Injector, private service:ApiService) {
    super(Injector);
    this.data = JSON.parse(this.service.getToken('currentTourDetails'));
  }

  ngOnInit() {
    this.headerData = {
      title: this.data.title,
      closeModalButton: true
    }
  }

  ngOnDestroy(){
    // console.log('closed')
  }

}
