import { Component, Injector } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BaseComponent } from './common/common.component';
import { ApiService } from './common/services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent extends BaseComponent {
  
  public showOfflineMessage = false;
  public showOnlineMessage = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private service: ApiService,
    // private network: Network
    private Injector: Injector
  ) {
    super(Injector);
    this.initializeApp();
  }

  async initializeApp() {
    // await this.service.showLoader();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.checkConnection();
      
    });
  }

  checkConnection(){
    let timeout;
    timeout = setTimeout(() => {
      if(timeout){
        clearTimeout(timeout);
      }
      if(!navigator.onLine && !this.showOfflineMessage){
        this.createToast('You are offline');
        this.showOfflineMessage = true;
        this.service.setConnection('offline');
      }
      else if(navigator.onLine && this.showOfflineMessage){
         this.showOfflineMessage = false;
        this.service.setConnection('online');
      }
      this.checkConnection();
    }, 5000);
  }
}
