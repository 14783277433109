import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './home/login/login.component';
import { RegisterComponent } from './home/register/register.component';

const routes: Routes = [
  { path: '', redirectTo: 'hoponindia', pathMatch: 'full' },
  {
    path: 'login', component: LoginComponent, pathMatch: 'full'
  },

  {
    path: 'register', component: RegisterComponent, pathMatch: 'full'
  },
  {
    path: 'profile',
    loadChildren: () => import('./home/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'hoponindia',
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
