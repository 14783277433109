import { Component, OnInit, Inject, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/common.component';
import { NavController } from '@ionic/angular';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModalComponent } from 'src/app/common/reusable/modal/modal.component';
import { ApiService } from 'src/app/common/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { IndexdbService } from 'src/app/common/services/indexdb.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PhonePe } from 'phonepesdk-web';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {

  public user:any = {};
  public countryCode = {
    dial_code:'',
    display:''
  };
  public submitted = false;
  public valid = false;
  public goBack:any = '';
  public iconSrc:any = "";
  public sdk:any;
  public phonePe: Boolean = false;
  
  public fd:any;

  constructor(public dialog: MatDialog, private Injector: Injector, private navCtrl: NavController, private service:ApiService,
     private aroute:ActivatedRoute, private index:IndexdbService, private sanitizer: DomSanitizer) {
    super(Injector);
    PhonePe.PhonePe.build(PhonePe.Constants.Species.web).then(ppsdk => {
      this.sdk = ppsdk;
      this.phonePe = true;
      
    }).catch((err) => {
      this.phonePe = false;
    })
    this.service.hideLoader();
    if(this.aroute.snapshot.queryParams.backURL){
      this.goBack = this.aroute.snapshot.queryParams.backURL;
      // console.log(this.goBack)
    }
    this.countryCode = {
      display: 'IN +91',
      dial_code:'+91'
    }
   }

  ngOnInit() {
    if(navigator.userAgent.includes('Android')){
      this.getData();
    }
  }

  async getData(){
    let offlineImage:any = await this.index.retrieveData("0806");
    offlineImage.forEach(x => {
      if(x.media_name == "login_icon"){
        let URL:any;
        let file:any;
        URL = window.URL;
        file = x.media;
        this.iconSrc = URL.createObjectURL(file);
        //  = x.blobURL;
      }
    })
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '70%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        // console.log(result);
        this.countryCode.display = result.code+' '+result.dial_code;
        this.countryCode.dial_code = result.dial_code;
      }
    });
  }

  goToRegister(){
    if(this.user.phone){
      this.navCtrl.navigateRoot(['/register'], {queryParams:{phone:this.user.phone}})
    }
    else{
      this.navCtrl.navigateRoot('/register');
    }
  }

  submit(user){
    this.submitted = true;
    this.valid = true;
    if(!user.phone){
      this.createToast('Please enter mobile number');
      this.valid = false;
    }
    if(this.valid){
      this.fd = new FormData();
      this.fd.append('phone', this.countryCode.dial_code+user.phone);
      this.fd.append('device', 'pwa');
      this.fd.append('device_token','MSeHQk5dplOcMq9phvFO_pFkB1-7mqResd');
      this.fd.append('device_id','MSeHQk5dplOcMq9phvFO_pFssssdsd');
      this.fd.append('password','LPTE@123');
      this.service.API('post', this.ApiConstants.LOGIN, this.fd, false).then((data:any)=>{
        // console.log(data.data);
        if(data.status == 'success'){
          // console.log('set data')
          if(data.data.photo != null && data.data.phone != 'undefined' && !data.data.photo.includes('/custom-assets')){
            let urls = data.data.photo.split('.com');
            data.data.photo = urls[0] + '.com/custom-assets' + urls[1];
          }
          if(this.phonePe){
            this.service.setToken('pwa_user', JSON.stringify(data.data)).then(() => {
              if(this.goBack){
                this.createToast('Login Successfull');
                this.navCtrl.navigateRoot(this.goBack);
              }
              else{
                this.createToast('Login Successfull');
                this.navCtrl.navigateRoot('/hoponindia/dashboard');
              }
            });
          }
          else{
            this.service.setToken('pwa_user', JSON.stringify(data.data)).then(() => {
              if(this.goBack){
                this.createToast('Login Successfull');
                this.navCtrl.navigateRoot(this.goBack);
              }
              else{
                this.createToast('Login Successfull');
                this.navCtrl.navigateRoot('/hoponindia/dashboard');
              }
            });
          }
        }
        else if(data.status == 'error'){
          this.createToast(data.message);
        }
      })
    }
  }

  city(){
    this.navCtrl.navigateRoot('/hoponindia/dashboard');
  }

}
