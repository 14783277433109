export const APIConstants = {
    LOGIN : '/login/makelogin',
    REGISTER: '/user/register',
    CITY_LIST: '/user/citylist',
    CITY_TOUR_LISTING: '/tourlocation/tourlisting',
    CITY_PACKAGE_LISTING: '/package/list',
    PACKAGE_DETAIL_LISTING: '/package/tours',
    TOUR_DETAIL: '/user/tourlist',
    MY_TOURS: '/tourlocation/purchasetourlist',
    MY_PACKAGE: '/package/purchasepackagelist',
    NEARBY_TOUR: '/tourlocation/nearbypoint',
    SUGGEST_CITY: '/tourlocation/suggestcity',
    UPDATE_PROFILE: '/user/updateprofile',
    CHANGE_PASSWORD: '/user/changepassword',
    CHECK_PROMO: '/promo/check',
    GET_LANGUAGES: '/user/language'
}