import { Component, OnInit, Injector } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BaseComponent } from 'src/app/common/common.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModalComponent } from 'src/app/common/reusable/modal/modal.component';
import { LanguageModalComponent } from 'src/app/common/reusable/language-modal/language-modal.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/services/api.service';
import { PhonePe } from 'phonepesdk-web';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseComponent implements OnInit {

  public user:any = {};
  public countryCode:any = {};
  public language = {
    name:'English',
    id:'1'
  };
  public fd:any;
  public sdk:any;
  public phonePe: Boolean = false;

  constructor(public dialog: MatDialog, private navCtrl: NavController, private Injector: Injector, private aroute: ActivatedRoute, private service:ApiService) {
    super(Injector);
    PhonePe.PhonePe.build(PhonePe.Constants.Species.web).then(ppsdk => {
      this.sdk = ppsdk;
      this.phonePe = true;
      
    }).catch((err) => {
      this.phonePe = false;
    })
    this.service.hideLoader();
    if(this.aroute.snapshot.queryParams.phone){
      this.user.phone = this.aroute.snapshot.queryParams.phone;
    }
    this.countryCode = {
      display: 'IN +91',
      dial_code:'+91'
    }
    this.service.API('post', this.ApiConstants.GET_LANGUAGES, '', false).then((data:any) => {
      if(data.status == 'success'){
        this.service.setToken('languages', JSON.stringify(data.data));
      }
    })
   }

  ngOnInit() {}

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '70%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.countryCode.display = result.code+' '+result.dial_code;
        this.countryCode.dial_code = result.dial_code;
      }
    });
  }

  setLanguage():void{
    const dialogRef = this.dialog.open(LanguageModalComponent, {
      width: '80%',
      data:{type:'language'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.language.name = result.language_nativename;
        this.language.id = result.language_id;
      }
    });
  }

  login(){
    this.navCtrl.navigateBack(this.UrlConstants.LOGIN);
  }

  register(registerForm, user){
    if(registerForm.valid){
      this.user.valid = true;
      if(user.password != user.c_password){
        this.createToast('Password does not match. Please try again');
        this.user.valid = false;
      }
      if(this.user.valid){
        this.fd = new FormData();
        this.fd.append('phone', this.countryCode.dial_code+user.phone);
        this.fd.append('name', user.username);
        this.fd.append('device_token','MSeHQk5dplOcMq9phvFO_pFkB1-7mqResd');
        this.fd.append('device_id','MSeHQk5dplOcMq9phvFO_pFssssdsd');
        this.fd.append('device','pwa');
        this.fd.append('password',user.password);
        this.fd.append('email', user.email);
        this.fd.append('language_id', this.language.id);
        this.service.API('post', this.ApiConstants.REGISTER, this.fd, false).then((data:any) => {
          if(data.status == 'success'){
            if(this.phonePe){
              this.service.setToken('pwa_user', JSON.stringify(data.data)).then(() => {
                this.navCtrl.navigateRoot('/hoponindia/dashboard');
              });
            }
            else{
              this.service.setToken('pwa_user', JSON.stringify(data.data)).then(() => {
                this.navCtrl.navigateRoot('/hoponindia/dashboard');
              });
            }
          }
          else if(data.status == 'error'){
            this.createToast(typeof(data.message) == 'object' ? data.message.password[0] : data.message);
          }
        })
      }
    }
  }

}
