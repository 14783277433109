import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; 
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { BaseComponent } from './common.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './reusable/modal/modal.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './reusable/header/header.component';
import { LanguageModalComponent } from './reusable/language-modal/language-modal.component';
import { IndexdbService } from './services/indexdb.service';
import { HTTP } from '@ionic-native/http/ngx';
import { ResolverService } from './services/resolver.service';
import { DetailModalComponent } from './reusable/detail-modal/detail-modal.component';
import { PreloadImageComponent } from './reusable/preload-image/preload-image.component';
import { NoRightClickDirective } from './reusable/no-right-click.directive';

@NgModule({
  declarations: [
    BaseComponent,
    ModalComponent,
    HeaderComponent,
    LanguageModalComponent,
    DetailModalComponent,
    PreloadImageComponent,
    NoRightClickDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatButtonModule, 
    MatInputModule, 
    MatDialogModule,
    HttpClientModule,
    MatFormFieldModule,
    MatIconModule
  ],
  providers: [
    ApiService, IndexdbService, HTTP, ResolverService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ HeaderComponent, PreloadImageComponent, NoRightClickDirective ],
  entryComponents: [ModalComponent, HeaderComponent, LanguageModalComponent, DetailModalComponent, PreloadImageComponent]
})
export class CommonnModule { }
