import { Component, OnInit, Input, Injector } from '@angular/core';
import { NavController, MenuController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LanguageModalComponent } from '../language-modal/language-modal.component';
import { ModalController } from '@ionic/angular';
import { DetailModalComponent } from '../detail-modal/detail-modal.component';
import { BaseComponent } from '../../common.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {

  @Input() headerData: any;
  public showLocationButton:Boolean = false;
  public language:any = {};

  constructor(
    public dialog: MatDialog,
    private navCtrl: NavController, 
    private menu: MenuController, 
    private service:ApiService, 
    private router:Router,
    private modalCtrl:ModalController,
    private Injector:Injector
    ) {
      super(Injector);
      // if(this.headerData.installBanner){
      //   console.log(this.headerData.installBanner)
      // }
      if(this.service.getToken('languages')){
        let languageData = JSON.parse(this.service.getToken('languages'));
        if(this.service.getToken('pwa_user')){
          let user = JSON.parse(this.service.getToken('pwa_user'))
          languageData.forEach(data => {
            if(data.language_id == user.language_id){
              this.language = data;
            }
          });
        }
        else{
          languageData.forEach(data => {
            if(data.language_id == '1'){
              this.language = data;
            }
          });
        }
      }
   }

  ngOnInit() {
    // console.log(this.headerData.installBanner);
  }

  goTo(){
    this.navCtrl.navigateRoot([this.headerData.url]);
  }

  // Go to city listing page
  gotToCity(){
    this.navCtrl.navigateRoot('/hoponindia/dashboard',  { queryParams: { showButton: true } });
  }

  // Go to my location page
  gotToLocation(){
    this.navCtrl.navigateRoot('/hoponindia/my-location');
  }

  // Exiting the Regular tour modal functionality
  exitTour(){
    let tour = '';
    if(this.service.getToken('currentMode')){
      tour = 'regular';
    }
    else{
      tour = 'museum';
    }
    const dialogRef = this.dialog.open(LanguageModalComponent, {
      width: '70%',
      data:{type:'exitTour', tour: tour}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result == 'yes'){
          this.navCtrl.navigateRoot([this.headerData.url]);
        }
      }
    });
  }

  getLanguage(){
    return this.language;
  }

  // Language popup modal
  openLanguage(){
    const dialogRef = this.dialog.open(LanguageModalComponent, {
      width: '80%',
      data:{type:'language'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(this.service.getToken('pwa_user')){
          let user = JSON.parse(this.service.getToken('pwa_user'));
          user.language_name = result.language_name;
          user.language_nativename = result.language_nativename;
          user.language_id = result.language_id;
          user.language_code = result.language_code;
          this.service.setToken('pwa_user', JSON.stringify(user));
        }
        this.language = result;
      }
    });
  }

  gotToEdit(){
    this.navCtrl.navigateRoot('/profile/detail');
  }

  gotToMuseum(){
    this.navCtrl.navigateRoot(this.headerData.markerUrl)
  }

  // Regular tour detail modal open functionality
  async detailModal(){
    const modal = await this.modalCtrl.create({
      component: DetailModalComponent
    })
    await modal.present();
  }

  // Regular tour detail modal close functionality
  async closeIonModal(){
    await this.modalCtrl.dismiss();
  }

  shareTour(){
    if(this.headerData.contacts.length > 0){
      console.log(this.headerData.contacts)
      let fd = new FormData();
      fd.append('phone_number', this.headerData.contacts);
      fd.append('purchase_tour_id', this.service.getToken('currentTour'));
      this.service.API('post', '/tourlocation/sharetours', fd, false).then((data:any) => {
        console.log(data);
        if(data.status == 'success'){
          this.createToast(data.message);
          this.navCtrl.navigateRoot(['/hoponindia/my-tour/tour/detail']);
        }
        else{
          this.createToast(data.message)
        }
      })
    }
    else{
      this.createToast('Please select atleast 1 contact to share the tour');
    }
  }

  // Go to tour share functionality page
  goToShare(){
    if(parseInt(this.headerData.shareData.share) > 0){
      this.service.setToken('currentTour', this.headerData.shareData.tour);
      this.navCtrl.navigateRoot(['/hoponindia/my-tour/tour/share'], {queryParams: {url: '/hoponindia/my-tour/tour/detail', share:this.headerData.shareData.share}});
    }
    else{
      this.createToast('Tour share limit reached');
    }
  }

  // Exit tour share functionality page
  exitShare(){
    if(this.headerData.isPackage){
      this.navCtrl.navigateRoot([this.headerData.url], {replaceUrl: true});
    }
    else{
      this.navCtrl.navigateRoot([this.headerData.url], {queryParams:{tour:this.service.getToken('currentTour')}, replaceUrl: true});
    }
  }

  exitDetail(){
    this.navCtrl.navigateRoot(this.headerData.url);
  }

  backPackage(){
    this.navCtrl.setDirection('back');
    this.navCtrl.navigateRoot([this.headerData.url], {replaceUrl: true})
  }

}
