import { Component, OnInit, Injector, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BaseComponent } from '../../common.component';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss'],
})
export class LanguageModalComponent extends BaseComponent implements OnInit {

  public term = '';
  public languages:any[];
  public filteredData:any;
  public type = '';
  public suggest_City = '';
  public promocode = '';
  public languageData:any = [];

  constructor(private Injector: Injector, public router: Router, 
    public dialogRef: MatDialogRef<LanguageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service:ApiService, private navCtrl: NavController) {
    super(Injector);
    // console.log(this.data);
    this.languageData = JSON.parse(this.service.getToken('languages'));
    if(this.languageData){
      this.searchFilteredTerm();
    }
   }

   searchFilteredTerm(){
    this.filteredData = this.languageData.filter((item) =>{
      return item.language_name.toLowerCase().includes(this.term.toLowerCase());
    });
  }

  suggestCity(){
    // this.service.isAuthenticated().then((isAuth:any) => {
    this.service.API('post', '/user/checklogin', '', true).then((data:any) => {
      if(data.status == 'success'){
        this.dialogRef.close(true);
      }
      else{
        this.dialogRef.close('');
        this.navCtrl.navigateRoot('/login');
      }
    })
  }

  suggest(){
    // console.log('suggested a city')
  }

  ngOnInit() {}

  availableCities(){
    this.dialogRef.close('');
    this.navCtrl.navigateRoot('/hoponindia/dashboard',  { queryParams: { showButton: true } });
  }

  applyPromo(){
    if(this.promocode){
      this.dialogRef.close(this.promocode);
    }
  }

  openWindow(link){
    window.open(link);
    this.dialogRef.close();
  }

}
