import { Component, OnInit, PLATFORM_ID, Injector } from '@angular/core';
import { URLConstants } from './constants/urlConstants';
import { APIConstants } from './constants/apiConstants';
import { Countries } from './services/country';
import { Nearby } from './services/nearby';
import { ToastController } from '@ionic/angular';

@Component({
    selector: 'parent-comp', 
    template: ``,
    providers: []
})

export class BaseComponent {

    public UrlConstants = URLConstants;
    public ApiConstants = APIConstants;
    public countryList = Countries;
    public NearBy = Nearby;
    public toastController : ToastController;

    // public urlConstants: URLConstants;
    constructor(injector: Injector){
            this.toastController = injector.get(ToastController);
    }

///////////////////////////////////////////////
// Toast Notification
///////////////////////////////////////////////
async createToast(message) {
    const toast = await this.toastController.create({
        message: message,
        duration: 2000
    });
    toast.present();
}

///////////////////////////////////////////////
// Decode Overview Polyline
///////////////////////////////////////////////
decode(str, precision?) {
    var index = 0,
        lat = 0,
        lng = 0,
        coordinates = [],
        shift = 0,
        result = 0,
        byte = null,
        latitude_change,
        longitude_change,
        factor = Math.pow(10, precision || 5);
  
    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {
  
        // Reset shift, result, and byte
        byte = null;
        shift = 0;
        result = 0;
  
        do {
            byte = str.charCodeAt(index++) - 63;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);
  
        latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));
  
        shift = result = 0;
  
        do {
            byte = str.charCodeAt(index++) - 63;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);
  
        longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));
  
        lat += latitude_change;
        lng += longitude_change;
  
        coordinates.push([lat / factor, lng / factor]);
    }
  
    return coordinates;
  };


}