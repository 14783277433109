import { Component, OnInit, Inject, Injector } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BaseComponent } from '../../common.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent extends BaseComponent implements OnInit {

  public term = '';
  public countries:any[];
  public filteredData:any;

  constructor(private Injector: Injector,  
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      super(Injector);
      this.countries = this.countryList.data;
      this.searchFilteredTerm();
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  searchFilteredTerm(){
    this.filteredData = this.countries.filter((item) =>{
      return item.name.toLowerCase().includes(this.term.toLowerCase());
    });
  }

  ngOnInit() {}

}
