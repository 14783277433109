import { Injectable } from '@angular/core';
import { openDB, DBSchema } from 'idb';
import { ApiService } from './api.service';
import { ToastController } from '@ionic/angular';
// import Dexie from 'dexie';
import Dexie from '@dpogue/dexie';

@Injectable({
  providedIn: 'root'
})

export class IndexdbService {

  public db:any;

  constructor(private service:ApiService) {}
  
  async storeData(data:any){
    this.db = new Dexie('HOI_Store');
    this.db.version(1).stores({
      hoi: 'media_name, media_type, tour_no, media, blobURL'
    });
    
    this.db.open().catch((error) => {
      alert("Error during connecting to database : " + error);
    });
    this.db.hoi.where('media_name').equals(data.media_name).count((count) => {
      if(count == 1){
        // console.log('already added');
        this.service.setDownloadCount();
      }
      else{
        var xhr = new XMLHttpRequest(),
        blob;
        // Get the Video file from the server.
        xhr.open("GET", data.media_url, true);     
        xhr.responseType = 'blob';
        let self = this;
        xhr.addEventListener("load", function () {
          if (xhr.status === 200) {
              blob = xhr.response;
              
              let storing = {
                tour_no: data.tour_no,
                media_name: data.media_name,
                media_type: data.media_type,
                media: blob
              }
              self.db.hoi.add(storing).then(function() {
                return true;
              }).then(function (data) {
                  // console.log('saved');
                  self.service.setDownloadCount();
              }).catch(function (e) {
                self.service.setDownloadError();
                // self.service.createToast('already added');
              });
          }
          else {
            self.service.setDownloadError();
          }
        }, false);
        xhr.onerror= function(e) {
          self.service.setDownloadError();
        };
        xhr.send();
      }
    });
  }

  retrieveData(key:any){
    return new Promise((resolve, reject) => {
      this.db = new Dexie('HOI_Store');
      this.db.version(1).stores({
        hoi: 'media_name, media_type, tour_no, media'
      });
      
      this.db.open().catch((error) => {
        alert("Error during connecting to database : " + error);
      });
      this.db.hoi.where('tour_no').equals(key).toArray((data) => {
        resolve(data);
      })
    })
  }

}
